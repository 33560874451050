<template>
  <div></div>
</template>
  
  <script>
import { tokenLogin } from '@/api/login'
export default {
  data() {
    return {
      token: ''
    }
  },
  created() {
    this.getToken()
  },
  methods: {
    getToken() {
      // let url = 'http://59.110.234.191/index.html?token=15028155600#'
      // var url = window.location.href
      // var temp1 = url.split('=')[1];
      // this.token = temp1.split('#')[0];
      this.token = 15028155600;
      console.log('token', this.token)
      if (this.token) {
        this.gettokenLogin()
      } else {
        // this.gettokenLogin()
        this.$toast.fail('token为空,请返回重新进入')
      }
    },
    async gettokenLogin() {
      const {
        data
      } = await tokenLogin({ token: this.token })
      if (data.code == 0) {
        var dats = {
          token: data.data
        }
        this.$store.dispatch("AddUser", dats)
        this.$router.push({ name: 'home' })
      } else {
        this.$toast.fail(data.msg)
      }
    },
  }
}
  </script>
  
 
  