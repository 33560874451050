//加载用户请求模块
import request from "@/utils/";

// 发送验证码

export function getSmsCode(data) {
    return request({
        url: "/api/getSmsCode",
        method: "post",
        data: data
    });
}
// h5登录
export function getLogin(data) {
    return request({
        url: "/api/h5login",
        method: "post",
        data: data
    });
}
// 获取token
export function tokenLogin(data) {
    return request({
        url: "/api/tokenLogin",
        method: "post",
        data: data
    });
}